import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const News = () => {
  return (
    <Layout>
      <SEO title="News" />
    </Layout>
  )
}

export default News
